import React, { useEffect, useState } from "react";
import "./Welcome-Signage.css";

// Please change these to the accurate props
function WelcomeSignageMarkup({ type, Background, Logo, Headline, Sub_Heading, ...props }) {
	
	var backgroundURL = {
     image: `url(${Background})`,
   };
//    console.log(imageURL);

	backgroundURL = backgroundURL.image.slice(4, -1);
//    console.log(imageURL);

var logoURL = {
	image: `url(${Logo})`,
  };
//    console.log(imageURL);

logoURL = logoURL.image.slice(4, -1);
//    console.log(imageURL);

  // Do anything you want here
  return (
    <>
  		<div className="WelcomeSignageMarkupPage">
				<div className="WelcomeSignageMarkupContainer">
					<div className="WelcomeSignageMarkupImg-holder">
              		<img src={ ' ' } alt="" id="SparkCentreCovidImg" className="SparkCentreCovidImg" />
            			{/* <img src={backgroundURL} alt="" id="WelcomeSignageMarkupImgBackground" className="WelcomeSignageMarkupImgBackground" /> */}
          				
						<div className='WelcomeSignageMarkupHeadline'>
            				<span>{ Headline }</span>
          				</div>

          				<img src={logoURL} alt="Spark Centre Logo"  className="WelcomeSignageMarkupLogo"/>
            
          				<div className='WelcomeSignageMarkupSubHeading'>
            				<span>{Sub_Heading}</span>
          				</div>
          			</div>
				</div>
			</div>
    </>
  );
}

export default WelcomeSignageMarkup;