import React, { useEffect, useState } from "react";
import "./Alert-Signage.css";

// Please change these to the accurate props
function AlertSignageMarkup({ type, Image, ...props }) {
	
	let imageURL = {
     image: `url(${Image})`,
   };
   console.log(imageURL);

   imageURL = imageURL.image.slice(4, -1);
//    console.log(imageURL);

  // Do anything you want here
  return (
    <>
  		<div className="AlertSignagePage">
			<div className="AlertSignageContainer">
				<div className="AlertSignageImg-holder"  id="AlertSignageImg-holder">
					<img src={imageURL} alt="" id="AlertSignageImg" className="AlertSignageImg" />
          <img src={imageURL} alt="" id="AlertSignageImg" className="AlertSignageImgBackground" />
				</div>
			</div>
		</div>
    </>
  );
}

export default AlertSignageMarkup;