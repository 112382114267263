const API = {
  retrieveAlertSignageRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Alert_Signage/`)
      .then(r => r.json());
  },
  retrieveSparkCentreCovidRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/General_Template/`)
      .then(r => r.json());
  },
  retrieveVideoPlaybackRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Video_Playback/`)
      .then(r => r.json());
  },
  retrieveWelcomeSignageRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/Welcome_Signage/`)
      .then(r => r.json());
  },
  getImageUrl(id) {
    return id ? `${process.env.REACT_APP_API_URL}/assets/${id}` : '';
  }
}

export default API