import React, { useEffect, useState } from "react";
import "./Video-Playback.css";

// Please change these to the accurate props
function VideoPlaybackMarkup({ type, Video, ...props }) {
	
	var videoURL = {
		video: `url(${Video})`,
   	};
//    console.log(imageURL);

	videoURL = videoURL.video.slice(4, -1);
//    console.log(imageURL);

  // Do anything you want here
  return (
    <>
  		<div className="VideoPlaybackPage">
			<div className="VideoPlaybackContainer">
				<div className="vid-holder">
            		<video src={videoURL} id="vid" className="vid" autoPlay muted loop></video >
            		<video src={videoURL} id="vidBackground" className="vidBackground" autoPlay muted loop></video >
				</div>
			</div>
		</div>
    </>
  );
}

export default VideoPlaybackMarkup;