import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import AlertSignage from './common/Alert-Signage/Alert-Signage';
import VideoPlayback from './common/Video-Playback/Video-Playback';
import SparkCentreCovid from './common/Spark-Centre-Covid/Spark-Centre-Covid';
import WelcomeSignage from './common/Welcome-Signage/Welcome-Signage';



function App() {
  return (
    <Router>
      <Switch>      
        <Route exact path="/">
          <Redirect to="/" noIntro />
        </Route>
        <Route exact path="/Alert-Signage">
          <AlertSignage type="Alert_Signage" noIntro />
        </Route>
        <Route exact path="/Video-Playback">
          <VideoPlayback type="Video_Playback" noIntro />
        </Route>
        <Route exact path="/General-Template">
          <SparkCentreCovid type="Spark_Centre_Covid" noIntro />
        </Route>
        <Route exact path="/Welcome-Signage">
          <WelcomeSignage type="Welcome_Signage" noIntro />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
