import React, { useEffect, useState } from "react";
import "./Spark-Centre-Covid.css";

// Please change these to the accurate props
function SparkCentreCovidMarkup({ type, Header, Background, Title, Paragraph, ...props }) {
	
	var headerURL = {
     	image: `url(${Header})`,
   	};
//    console.log(imageURL);

	headerURL = headerURL.image.slice(4, -1);
//    console.log(imageURL);

var backgroundURL = {
	image: `url(${Background})`,
  };
//    console.log(imageURL);

backgroundURL = backgroundURL.image.slice(4, -1);
//    console.log(imageURL);

  // Do anything you want here
  return (
    <>
  		<div className="SparkCentreCovidPage">
          <div className="SparkCentreCovidContainer">
            <div className="SparkCentreCovidImg-holder">
              <img src={ ' ' } alt="" id="SparkCentreCovidImg" className="SparkCentreCovidImg" />
              {/* <img src={ backgroundURL } alt="" id="SparkCentreCovidImgBackground" className="SparkCentreCovidImgBackground" /> */}
              <img src={ headerURL } alt="" id="SparkCentreCovidHeader" className="SparkCentreCovidHeader" />

              <div className='SparkCentreCovidTitle'>
            	  <span>{ Title }</span>
          		</div>
              <hr></hr>
          	  <div className='SparkCentreCovidParagraph'>
            		<span>{ Paragraph }</span>
          		</div>
            </div>
          </div>
        </div>
    </>
  );
}

export default SparkCentreCovidMarkup;