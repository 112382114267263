import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SparkCentreCovidMarkup from "./Spark-Centre-Covid-Markup";
import API from "../../Api";
import { preloadImage, useLocalStorage } from "../../utils";

function SparkCentreCovid(props) {
  const { itemId } = useParams();
  const [introRunning, setIntroRunning] = useState(!props.noIntro);
  const [items, setItems] = useLocalStorage(`${props.type}_items`, []);
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    `${props.type}_index`,
    0
  );
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  // refresh and cache items
  useEffect(() => {
    async function run() {
      try {
        const items = await API.retrieveSparkCentreCovidRecords(props.type);
        setItems(items.data);
      } catch (e) {
        console.error(e);
      }
    }

    run();

    const interval = setInterval(run, 5000);

    return () => clearInterval(interval);
  }, [props]);

  // if we have itemId in the route, render single item, otherwise rotate items
  useEffect(() => {
    if (itemId) {
      // eslint-disable-next-line eqeqeq
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
      }
    } else {
      let i = currentItemIndex;

      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);

      storeCurrentItemIndex(i);
    }
  }, [currentItemIndex, items, itemId]);

  // preload image for further use
  useEffect(() => {
    if (currentItem.image) {
      preloadImage(API.getImageUrl(currentItem.image));
    }
  }, [currentItem]);

  return (
    <>
      <SparkCentreCovidMarkup
        visible={!introRunning}
        type={props.type}
        Title={currentItem.Title}
        Paragraph={currentItem.Paragraph}
        Header={API.getImageUrl(currentItem.Header)}
        Background={API.getImageUrl(currentItem.Background)}
      />
    </>
  );
}

export default SparkCentreCovid;